import {EagleApiInterface, EagleModuleApiInterface} from 'kernel/api/api'

export default (api: EagleApiInterface) => {
  api.collection.memberApi = <EagleModuleApiInterface>{
    getMemberToken: (id: string) => api.request({
      url: `/api/admin/member/token/${id}`,
      type: 'get',
    }),
    read: (id: string) => api.request({
      url: `/api/admin/member/${id}`,
      type: 'get',
    }),
    index: (params = {}) => api.request({
      url: `/api/admin/member/index`,
      type: 'get',
      params,
    }),
    create: (params = {}) => api.request({
      url: `/api/admin/member`,
      type: 'post',
      params,
    }),
    getOfficialMemberToken: (providerId, params = {}) => api.request({
      url: `/api/admin/member/official-member-token/${providerId}`,
      type: 'post',
      params,
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/member/${id}/update`,
      type: 'put',
      params,
    }),
    patch: (id: string, column: string, value: any) => api.request({
      url: `/api/admin/member/${id}`,
      type: 'patch',
      params: {
        column,
        value,
      },
    }),
    resetPassword: (id: string, params = {}) => api.request({
      url: `/api/admin/member/${id}/reset-password`,
      type: 'put',
      params,
    }),
    delete: (id: string) => api.request({
      url: `/api/admin/member/${id}`,
      type: 'delete',
    }),
    batch: (targets: string[], column: string, value: any) => api.request({
      url: `/api/admin/member/batch`,
      type: 'patch',
      params: {
        ids: targets,
        column,
        value,
      },
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/member/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
    // 取得該會員管理的供應商或餐廳
    cooperation: (params, member_id: string, type: 'provider' | 'store') => api.request({
      url: `/api/admin/members/${member_id}/${type}`,
      type: 'get',
      params
    }),
  }

  api.collection.providerMemberApi = <EagleModuleApiInterface> {
    index: (params = {}) => api.request({
      url: `/api/admin/provider-member/index`,
      type: 'get',
      params,
    }),
    delete: (provider_id: string, member_id: string) => api.request({
      url: `/api/admin/provider-member/${provider_id}/${member_id}`, 
      type: 'delete',
    }),
    batchDelete: (targets: string[]) => api.request({
      url: `/api/admin/provider-member/batch`,
      type: 'delete',
      params: { ids: targets },
    }),
    update: (id: string, params = {}) => api.request({
      url: `/api/admin/provider-member/${id}/update`,
      type: 'put',
      params,
    }),
  }

  api.collection.storeMemberApi = <EagleModuleApiInterface> {
    index: (store_id: string, params = {}) => api.request({
      url: `/api/admin/store/${store_id}/index`,
      type: 'get',
      params,
    }),
    delete: (store_id: string, member_id: string) => api.request({
      url: `/api/admin/store/${store_id}/${member_id}`,
      type: 'delete',
    }),
    // batchDelete: (targets: string[]) => api.request({
    //   url: `/api/admin/store/batch`,
    //   type: 'delete',
    //   params: { ids: targets },
    // }),
  }
}
